/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Box } from '@mantine/core';
import React, { useEffect } from 'react';
import UpcomingAppointments from './UpcomingAppointments';
import NotificationCard from './NotificationCard';
import { useMedplum } from '@medplum/react';
import FeaturedCards from './Cards/FeaturedCards';
import { Header } from '../components/Header';
import AIInsights from './AIInsights/AIInsights'
import Records from './Records/index';
import PatientMedicalHistory from './MedicalHistory/index'
import { IconDownload } from '@tabler/icons-react';
import MedicalRecord from './MedicalRecord';
import { fetchPatientDetails } from '../utils/CustomAPI';

export interface PatientProps {
  id: string;
  name: any;
  address: any;
  telecom: any;
  gender: string;
  birthDate: string;
  identifier: any;
}

const Index = () => {
  const medplum = useMedplum();
  const [openedMedicalRecord, setOpenedMedicalRecord] = React.useState(false);
  const [patientData, setPatientData] = React.useState<PatientProps>();
  const currentHour = new Date().getHours();
  let greeting;

  if (currentHour < 12) {
    greeting = 'Good Morning';
  } else if (currentHour < 18) {
    greeting = 'Good Afternoon';
  } else {
    greeting = 'Good Evening';
  }

  useEffect(() => {
    const graphqlQuery = `${
      '{ Patient(id: "' +
      medplum.getProfile()?.id +
      '") { resourceType id name { given family } address { line city state country } telecom {system value} gender birthDate identifier { value } } }'
    }`;

    fetchPatientDetails(medplum, graphqlQuery)
      .then((response: any) => {
        setPatientData(response?.data.Patient as PatientProps);
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
      });

  }, []);

  return (
    <>
      <Header />
      <Box px="lg" py="sm" className="dashboard-container tw-bg-[#fff]">
        <div className="tw-block tw-w-full tw-p-2 xs:tw-p-0">
          <div className="tw-bg-[#F9F5FF] tw-p-2 tw-rounded-lg tw-py-3 tw-flex tw-justify-between tw-items-center tw-opacity-[1]">
            <div>
              <h5 className="tw-text-[18px] xs:tw-text-[18px] tw-font-semibold tw-tracking-tight tw-text-[#101828]">
                {greeting}, {medplum.getProfile()?.name?.[0].given?.[0] + ' ' + medplum.getProfile()?.name?.[0].family}
              </h5>
              <h6 className="tw-text-[#475467] tw-text-[14px]">Have a nice day</h6>
            </div>
            <div className=''>
              <button type="button" className="tw-text-[#6941C6] tw-font-semibold tw-rounded-lg tw-text-sm tw-px-5 tw-py-2 tw-text-center tw-inline-flex tw-items-center tw-me-2 tw-mb-2 tw-border tw-border-[#D6BBFB] tw-w-max" onClick={() => setOpenedMedicalRecord(true)}>
                <IconDownload className="tw-me-2" color='#9552E8'/>
                Medical Records
              </button>
              <h5 className="tw-text-[#101828] tw-font-semibold">Medical Record No : {patientData?.identifier ? patientData?.identifier[0]?.value : 'NA'}</h5>
            </div>
          </div>
          <h5 className="tw-text-[18px] tw-text-[#101828] tw-font-semibold tw-mt-4">Health Overview</h5>
          <FeaturedCards />
          <div className="tw-flex tw-gap-5 xs:tw-gap-0 xs:tw-flex-wrap sm:tw-flex-wrap md:tw-flex-wrap tw-mb-6">
            <div className="tw-flex tw-gap-4 tw-w-full xs:tw-w-[100%] sm:tw-w-[100%] md:tw-w-[100%]">
              <UpcomingAppointments />
              <NotificationCard />
            </div>
          </div>
          <AIInsights />
          <Records />
          <PatientMedicalHistory />
        </div>
      </Box>
      {openedMedicalRecord && <MedicalRecord opened={openedMedicalRecord} close={()=> setOpenedMedicalRecord(false)} />}
    </>
  );
};

export default Index;
