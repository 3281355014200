const FilterTags = (props: any): JSX.Element => {
  const { activeFilterTab, setActiveFilterTab } = props;

  return (
    <div className="tw-flex tw-w-fit tw-mb-4 tw-border tw-rounded-lg tw-border-[#D0D5DD]">
      <div
        defaultValue=""
        onClick={() => setActiveFilterTab('')}
        className={`tw-w-1/4 tw-flex tw-justify-center tw-cursor-pointer tw-text-center tw-rounded-l-lg tw-text-[#344054] tw-font-semibold tw-border-r tw-border-[#D0D5DD] tw-py-2 tw-px-[1.2rem] ${activeFilterTab === '' ? 'tw-bg-[#e1e3e796]' : ''}`}
      >
        All
      </div>
      <div
        defaultValue="pending"
        onClick={() => setActiveFilterTab('pending')}
        className={`tw-w-1/4  tw-flex tw-justify-center tw-cursor-pointer tw-text-center tw-text-[#344054] tw-font-semibold tw-border-r tw-border-[#D0D5DD] tw-py-2 tw-px-[1.2rem] ${activeFilterTab === 'pending' ? 'tw-bg-[#e1e3e796]' : ''}`}
      >
        Upcoming
      </div>
      <div
        defaultValue="cancelled"
        onClick={() => setActiveFilterTab('cancelled')}
        className={`tw-w-1/4 tw-flex tw-justify-center tw-cursor-pointer tw-text-center tw-text-[#344054] tw-font-semibold tw-border-r tw-border-[#D0D5DD] tw-py-2 tw-px-[1.2rem] ${activeFilterTab === 'cancelled' ? 'tw-bg-[#e1e3e796]' : ''}`}
      >
        Cancelled
      </div>
      <div
        defaultValue="booked"
        onClick={() => setActiveFilterTab('booked')}
        className={`tw-w-1/4 tw-flex tw-justify-center tw-cursor-pointer tw-text-center tw-text-[#344054] tw-font-semibold  tw-py-2 tw-px-[1.2rem] ${activeFilterTab === 'booked' ? 'tw-bg-[#e1e3e796]' : ''}`}
      >
        Completed
      </div>
    </div>
  );
};


export default FilterTags;