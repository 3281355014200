/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect } from 'react';
import ActivitySummary from './ActivitySummary';
import HeartRate from './HeartRate';
import BloodPressure from './BloodPressure';
import Weight from './Weight';
import { useMedplum } from '@medplum/react';
import { HealthOverview } from '../../utils/constant';
import { DataPoint } from '../../interfaces/DataPoint';

const FeaturedCards = () => {
  const medplum = useMedplum();
  const [stepCountData, setStepCountData] = React.useState<DataPoint[]>([]);
  const [heartRateData, setHeartRateData] = React.useState<DataPoint[]>([]);
  const [bloodPressureData, setBloodPressureData] = React.useState<DataPoint[]>([]);
  const [weightData, setWeightData] = React.useState<DataPoint[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true);
    medplum
    .search(
      'Observation' as
        | 'Observation',
      {
        _sort: '-_lastUpdated',
        _total: 'accurate',
        _count: 1000,
        patient: `Patient/${ medplum.getProfile()?.id}`,
      }
    )
    .then((res: any) => {
      setIsLoading(false);
      //Step count data
      const stepCountData = res.entry.filter((entry: any) => entry.resource.code.text === HealthOverview.STEPCOUNT)
      .map((entry: any) => ({
        value: entry.resource.valueQuantity?.value,
        unit: entry.resource.valueQuantity?.unit.toString(),
        date: entry.resource?.meta?.lastUpdated
      }));
      setStepCountData(stepCountData);

      //Heart rate data
      const heartRateData = res.entry.filter((entry: any) => entry.resource.code.text === HealthOverview.HEARTRATE)
      .map((entry: any) => ({
        value: entry.resource.valueQuantity?.value,
        unit: entry.resource.valueQuantity?.unit.toString()
      }));
      setHeartRateData(heartRateData);

      //blood pressure data
      const bloodPressureData = res.entry.filter((entry: any) => entry.resource.code.text === HealthOverview.BLOODPRESSURE)
      .map((entry: any) => ({
        value: entry.resource?.component?.[0].valueQuantity?.value,
        unit: entry.resource?.component?.[0].valueQuantity?.unit.toString()
      }));
      setBloodPressureData(bloodPressureData);

      //weight data
      const weightData = res.entry.filter((entry: any) => entry.resource.code.text === HealthOverview.WEIGHT || entry.resource.code.text === HealthOverview.BODYWEIGHT)
      .map((entry: any) => ({
        value: entry.resource.valueQuantity?.value,
        unit: entry.resource.valueQuantity?.unit.toString()
      }));
      setWeightData(weightData);
    })
    .catch((err: any) => {
      console.error(err);
    });
  }, []);

  return (
    <>
      <div className="tw-flex tw-gap-4 xs:tw-gap-0 tw-flex-nowrap lg:tw-flex-nowrap sm:tw-flex-wrap xs:tw-flex-wrap">
        <ActivitySummary stepCountData={stepCountData} isLoading={isLoading}/>
        <HeartRate heartRateData={heartRateData} isLoading={isLoading}/>
      </div>
      <div className="tw-flex tw-gap-4 xs:tw-gap-0 tw-flex-nowrap lg:tw-flex-nowrap sm:tw-flex-wrap xs:tw-flex-wrap">
        <BloodPressure bloodPressureData={bloodPressureData} isLoading={isLoading}/>
        <Weight weightData={weightData} isLoading={isLoading}/>
      </div>
    </>
  );
};

export default FeaturedCards;
