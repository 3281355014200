/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react'
import { progressReportSummary } from '../../utils/CustomAPI';
import { useMedplum } from '@medplum/react';

const ProgressReport = () => {
  const medplum = useMedplum();
  const [progressReportSummaryData, setProgressReportSummaryData] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getProgressReportSummary();
  }, []);

  const getProgressReportSummary = () => {
    setIsLoading(true);
    progressReportSummary(medplum, medplum.getProfile()?.id || '').then((res) => {
      setProgressReportSummaryData(res?.data);
      setIsLoading(false);
    }).catch((err) => {
      console.log(err);
    })
  }
  return (
    <>
      <div className='tw-bg-[#fff] tw-px-3 tw-pt-5 tw-rounded-b-lg tw-text-[#101828] tw-text-[18px] tw-font-semibold tw-border tw-border-[#EAECF0]'>
          <h4>Progress report based on the recent 3 Clinical Notes</h4>
          {isLoading ? (
              <>
              <span className="loading">
                <p className='tw-text[14px] tw-text-[#475467]'>Generating progress report</p>
                <span className="loading__dot"></span>
                <span className="loading__dot"></span>
                <span className="loading__dot"></span>
              </span>
            </>
          ) : (
            <div
              className="tab-content progress_report_tab"
              dangerouslySetInnerHTML={{ __html: progressReportSummaryData }}
            />
          )}
        </div> 
    </>
  )
}

export default ProgressReport;
