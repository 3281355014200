/* eslint-disable no-nested-ternary */
import { formatDate } from '@medplum/core';
import { IconCloudDownload, IconEye, IconPencil, IconShare, IconTrash } from '@tabler/icons-react';
import SearchAndFilter from '../../../components/SearchAndFilter';
import { useState } from 'react';
import FilterTags from '../../../components/FilterTags';
import { Center, Container, Text } from '@mantine/core';

interface PatientInfoProps {
  activeTab: number;
  resourceData: any[];
  activeInssuranceFilterTab: string;
  setActiveInssuranceFilterTab: (tab: string) => void;
  isLoading: boolean;
}

interface RowData {
  [key: string]: string;
}

export function PatientInfo({
  activeTab,
  resourceData,
  activeInssuranceFilterTab,
  setActiveInssuranceFilterTab,
  isLoading,
}: PatientInfoProps): JSX.Element {
  const [searchQuery, setSearchQuery] = useState('');
  const [activeFilterTab, setActiveFilterTab] = useState<string>('');

  // Define column configurations for each tab
  const tabColumns: Record<number, string[]> = {
    2: ['Name', 'Category', 'Visit Date', 'Issued', 'Reference Range', 'Effective Date', 'Status'],
    3: ['Name', 'Manufacturer', 'Date Administered', 'Vaccination Route', 'Body Site', 'Status'],
    4: ['Report Name', 'Category', 'Performer', 'Date Issued On', 'Effective Date', 'Status'],
    5: ['When', 'Type', 'Practitioner', 'Logs'],
    6: ['Plan Name', 'Group Name', 'Subscriber', 'Beneficiary', 'Payor', 'Relationship', 'Period', 'Status'],
    7: ['Name', 'Visit Type', 'Start date', 'End date', 'Diagnosis', 'Status'],
    8: ['Medication', 'Type', 'Start date', 'No of repeats', 'Status'],
    9: ['Assessment Name', 'Completion Date', 'Status'],
  };

  const columns = tabColumns[activeTab] || [];

  function getCategoryName(resource: any): string {
    const categories =
      resource?.category?.map((category: { coding: { display: string }[] }) => category.coding?.[0]?.display) || [];
    return categories.join(', ');
  }

  const getResourceData = (resource: any): RowData => {
    switch (activeTab) {
      case 2: // Observation
        return {
          Name: resource.code?.coding[0]?.code || '-',
          Category: resource.category?.[0]?.coding?.[0]?.display || '-',
          'Visit Date': formatDate(resource.effectiveDateTime) || '-',
          Issued: formatDate(resource.issued) || '-',
          'Reference Range': resource.referenceRange
            ? `${resource.referenceRange?.[0]?.low?.value} - ${resource.referenceRange?.[0]?.high?.value} ${resource.referenceRange?.[0]?.low?.unit}`
            : '-',
          'Effective Date': formatDate(resource.effectiveDateTime) || '-',
          Status: resource.status ? resource.status : '-',
        };
      case 3: // Immunization
        return {
          Name: resource.vaccineCode?.coding?.[0]?.display || '-',
          Manufacturer: resource.manufacturer?.display || '-',
          'Date Administered': formatDate(resource.occurrenceDateTime) || '-',
          'Vaccination Route': resource.route?.coding?.[0]?.display || '-',
          'Body Site': resource.site?.coding?.[0]?.display || '-',
          Status: resource.status ? resource.status : '-',

        };
      case 4: // Reports
        return {
          'Report Name': resource?.code?.coding[0].display || '-',
          Category: getCategoryName(resource) || '-',
          Performer: resource?.performer ? resource?.performer[0]?.display : '-',
          'Date Issued On': formatDate(resource.issued) || '-',
          'Effective Date': formatDate(resource.effectiveDateTime) || '-',

          Status: resource.status ? resource.status : '-',

        };
      case 5: // Appointment
        return {
          When: formatDate(resource?.start) || '-',
          Type: resource?.appointmentType?.coding?.[0]?.display || '-',
          Practitioner:
            resource?.participant?.find((participant: any) => participant.actor?.reference?.includes('Practitioner'))
              ?.actor?.display || '-',
        };
      case 6: // Insurance
        return {
          'Plan Name': resource?.class?.find((c: any) => c.type.coding[0].code === 'plan')?.name || '-',
          'Group Name': resource?.class?.find((c: any) => c.type.coding[0].code === 'group')?.value || '-',
          Beneficiary: resource?.beneficiary?.display || '-',
          Subscriber: resource?.subscriber?.display || '-',
          Payor: resource?.payor?.[0]?.display || '-',
          Relationship: resource?.relationship?.coding?.[0]?.display || '-',
          Period: resource?.period?.start
            ? `${formatDate(resource.period.start)} - ${formatDate(resource.period.end)}`
            : '-',
            Status: resource.status ? resource.status : '-',

        };
      case 7: // Procedures
        return {
          Name: resource.code?.coding?.[0]?.display || '-',
          'Visit Type': resource.category?.coding?.[0]?.display || '-',
          'Start date': formatDate(resource?.performedPeriod?.start) || '-',
          'End date': formatDate(resource?.performedPeriod?.end) || '-',
          Diagnosis: resource?.reasonCode?.[0]?.coding?.[0]?.display || '-',
          Status: resource.status ? resource.status : '-',

        };
      case 8: // Medication
        return {
          Medication: resource.medicationCodeableConcept?.coding?.[0]?.display || '-',
          Type: resource.medicationCodeableConcept?.coding?.[0]?.display || '-',
          'Start date': formatDate(resource?.authoredOn) || '-',
          'No of repeats': resource?.dispenseRequest?.numberOfRepeatsAllowed || '-',
          Status: resource.status ? resource.status : '-',

        };

      case 9: // Assessment
        return {
          'Assessment Name': resource?.questionnaire?.title || '-',
          'Completion Date': formatDate(resource?.response?.authored) || '-',
          Status: resource?.response?.status ? resource?.response?.status : '-',
        };
      default:
        return {} as Record<string, string>;
    }
  };

  const filteredResourceData = resourceData.filter((resource) => {
    const rowData = getResourceData(resource);
    const matchesSearchQuery = Object.values(rowData).some((value) => {
      return value.toString().toLowerCase().includes(searchQuery.toLowerCase());
    });

    if (activeTab === 5) {
      const matchesFilterTab = !activeFilterTab || resource.status === activeFilterTab;
      return matchesSearchQuery && matchesFilterTab;
    } else {
      return matchesSearchQuery;
    }
  });

  return (
    <div className="tw-w-full tw-mt-3">
      {/* Search, Clear */}
      {[3, 4].includes(activeTab) && (
        <div className="tw-flex tw-gap-3 tw-w-full tw-mb-2">
          <div className="tw-relative tw-w-full tw-flex tw-justify-center tw-mr-2 ">
            <span className="tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3">
              <svg className="tw-w-4 tw-h-4 tw-text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </span>

            <input
              type="search"
              className="tw-pl-10 tw-pr-6 tw-py-2 tw-w-full tw-text-sm tw-text-[#667085]  tw-bg-transparent tw-border tw-rounded-lg tw-border-gray-300 tw-outline-none focus:tw-border-gray-400 tw-font-normal"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <div className="tw-flex tw-items-center tw-gap-2">
            <button
              className="tw-border tw-border-[#D0D5DD] tw-shadow tw-rounded-lg  tw-font-semibold tw-py-[10px] tw-px-4"
              onClick={() => {
                setSearchQuery('');
              }}
            >
              Clear
            </button>
            <button className="tw-border tw-border-[#4DB5BA] tw-text-white tw-font-semibold tw-shadow tw-rounded-lg tw-py-[10px] tw-px-4 tw-bg-[#4DB5BA]">
              Search
            </button>
          </div>
        </div>
      )}

      
      <div className="tw-flex tw-w-full">
        {/* Filter Tabs */}
        {activeTab === 5 && <FilterTags activeFilterTab={activeFilterTab} setActiveFilterTab={setActiveFilterTab} />}

        {/* Filter Tabs */}
        {activeTab === 6 && (
          <div className="tw-mb-4 tw-flex tw-border-[1px] tw-rounded-lg tw-border-[#D0D5DD]">
            <div
              defaultValue="Coverage"
              onClick={() => setActiveInssuranceFilterTab('Coverage')}
              className={`tw-flex tw-justify-center tw-w-1/2 tw-cursor-pointer tw-text-center tw-rounded-l-lg tw-text-[#182230] tw-font-semibold tw-border-[1px] tw-border-[#D0D5DD] tw-py-2 tw-px-4 ${activeInssuranceFilterTab === 'Coverage' ? 'tw-bg-[#e1e3e796]' : ''}`}
            >
              Coverage
            </div>
            <div
              defaultValue="Claim"
              onClick={() => setActiveInssuranceFilterTab('Claim')}
              className={`tw-flex tw-justify-center tw-w-1/2 tw-cursor-pointer tw-text-center tw-rounded-r-lg tw-text-[#182230] tw-font-semibold tw-border-[1px] tw-border-[#D0D5DD] tw-py-2 tw-px-4 ${activeInssuranceFilterTab === 'Claim' ? 'tw-bg-[#e1e3e796]' : ''}`}
            >
              Claim
            </div>
          </div>
        )}

        {/* Search and Filter */}
        {[5, 6, 7, 8, 9].includes(activeTab) && (
          <SearchAndFilter searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        )}
      </div>

      {/* Table */}
      <div className="tw-bg-white ">
        <table className="tw-w-full tw-text-sm tw-text-left">
          <thead className="tw-text-xs tw-text-gray-700 tw-bg-gray-50 tw-border-b-[1px] tw-border-[#EAECF0]">
            <tr className="tw-py-3 tw-px-6">
              {columns.map((column, index) => (
                <th key={index} className="tw-py-3 tw-px-6 tw-text-[#475467] tw-font-medium tw-text-sm">
                  <div className="tw-flex tw-justify-between tw-items-center tw-w-max ">{column}</div>
                </th>
              ))}
              <th className="tw-py-3 tw-px-6 tw-text-[#475467] tw-font-medium "></th>
            </tr>
          </thead>

          <tbody>
            {!isLoading ? (
              filteredResourceData.map((resource: any, index: number) => {
                const rowData = getResourceData(resource);
                return (
                  <tr key={index} className="tw-border-b hover:tw-bg-gray-50 tw-cursor-pointer">
                    {columns.map((column, colIndex) => (
                      <td key={colIndex} className="tw-px-6 tw-py-4 tw-font-normal tw-text-[#475467]">
                        {column === 'Logs' ? (
                          <div>
                            <IconEye className="tw-text-[#475467] hover:tw-text-blue-500" />
                          </div>
                        ) : column === 'Status' ? (
                          <div>
                            <span
                              className={`tw-border tw-rounded-lg tw-py-[2px] tw-px-2 tw-font-medium ${
                                resource.status === 'not-done'
                                  ? 'tw-border-[#FFC080] tw-text-[#8B4513] tw-bg-[#F7D2C4]'
                                  : 'tw-border-[#ABEFC6] tw-text-[#067647] tw-bg-[#ECFDF3]'
                              }`}
                            >
                              {resource.status ? resource.status : rowData[column]}
                            </span>
                          </div>
                        ) : (
                          rowData[column] || '-'
                        )}
                      </td>
                    ))}
                    <td className="tw-px-6 tw-py-4">
                      {activeTab === 4 && (
                        <div className="tw-flex tw-gap-4">
                          <IconPencil
                            size={22}
                            stroke={'1.67px'}
                            className="tw-text-[#475467] hover:tw-text-blue-500 tw-cursor-pointer"
                          />
                          <IconTrash
                            size={22}
                            stroke={'1.67px'}
                            className="tw-text-[#475467] hover:tw-text-red-500 tw-cursor-pointer"
                          />
                        </div>
                      )}
                      {activeTab === 2 && (
                        <div className="tw-flex tw-gap-4">
                          <IconCloudDownload
                            size={22}
                            stroke={'1.67px'}
                            className="tw-text-[#475467] hover:tw-text-blue-500 tw-cursor-pointer"
                          />
                          <IconShare
                            size={22}
                            stroke={'1.67px'}
                            className="tw-text-[#475467] hover:tw-text-red-500 tw-cursor-pointer"
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={columns.length + 1}>
                  <Container>
                    <Center style={{ height: 150 }}>
                      <Text size="xl" c="dimmed">
                        Loading...
                      </Text>
                    </Center>
                  </Container>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {((resourceData?.length === 0 && !isLoading) ||
          (filteredResourceData.length === 0 && searchQuery !== '' && !isLoading)) && (
          <Container>
            <Center style={{ height: 150 }}>
              <Text size="xl" c="dimmed">
                No results
              </Text>
            </Center>
          </Container>
        )}
      </div>
    </div>
  );
}
