/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useMedplum } from '@medplum/react';
import React, { useEffect, useState } from 'react'
import { assessmentInsightsSummary } from '../../utils/CustomAPI';
import { formatDate } from '@medplum/core';

const AssessmentInsights = () => {
  const medplum = useMedplum();
  const [assessmentCompletionDate, setAssessmentCompletionDate] = useState('');
  const [assessmentInsightsSummaryData, setAssessmentInsightsSummaryData] = useState([]);

  useEffect(() => {
    getAssessmentInsightsSummary();
  },[])

  const getAssessmentInsightsSummary = () => {
    assessmentInsightsSummary(medplum, medplum.getProfile()?.id || '').then((res) => {
      setAssessmentCompletionDate(formatDate(res?.data?.authored));
      res?.data.extension.map((data: any) => {
        if (data.url === 'http://ai-insights') {
          const lines = data.valueString.split('\n');
          setAssessmentInsightsSummaryData(lines);
        }
      });
    }).catch((error) => {
      console.log(error);
    })
  };

  return (
    <>
      <div className='tw-bg-[#fff] tw-px-3 tw-pt-5 tw-rounded-b-lg tw-text-[#101828] tw-text-[18px] tw-font-semibold tw-border tw-border-[#EAECF0]'>
        <h4>Insights for Self assessment completed on {assessmentCompletionDate}</h4>
        <ul className="tab-content assessment_insights_tab">
          {assessmentInsightsSummaryData?.map((line: any, index: number) => (
            <li key={index} style={{ paddingBottom: '5px' }}>
              {line?.replace(/^- /, '')}
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default AssessmentInsights
