/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Button, Skeleton, Select } from '@mantine/core'
import React, { useEffect, useState } from 'react';
import NextGenLogo from '../../static/img/logos/nextgen-logo.svg';
import EpicLogo from '../../static/img/logos/epic-logo.svg';
import AthenaLogo from '../../static/img/logos/athena-logo.svg';
import CernerLogo from '../../static/img/logos/cerner-logo.svg';
import { getOrganization } from '../utils/CustomAPI';
import { useMedplum } from '@medplum/react';
import organizationList from './pragmaconnect_organizations.json';
import CryptoJS from 'crypto-js';

interface DropDownValueModel {
  clientId: string;
  address: string;
}

interface EHRValues {
  ehr: string;
  key: string;
  name: string;
  id: string;
}

const Constants = {
  clientId: 'client_id',
  response_type: 'response_type',
  redirectURI: 'redirectURI',
  scope: 'scope',
  state: 'state',
  epicauthmobile: 'epicauthmobile',
};

const Urls = {
  oauth2_authorize: '/oauth2/authorize',
  secretKey: '5ad1ec094705b14eb05ac7c61540ee89', // Replace with your actual secret key
};

const LoginWithSSO = () => {
  const medplum = useMedplum();
  const [ehr, setEhr] = useState<EHRValues>();
  const [checkOrganization, setCheckOrganization] = useState<EHRValues>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCheckLoginBtn, setIsCheckLoginBtn] = useState<boolean>(false);
  const [selectedOrganization, setSelectedOrganization] = useState({
    name: '',
    address: '',
    clientId: '',
  });

  const updatedOrganizationList = organizationList.map((item: any) => {
    const { address, ...rest } = item;
    return { label: address, value: item.label, address: address, ...rest };
  });

  useEffect(() => {
    getOrganizationByName('Epic Integration Demo Org');
  }, []);

  const getOrganizationByName = (name: string) => {
    setIsLoading(true);
    getOrganization(medplum, name).then((response) => {
      setEhr(response);
      setIsLoading(false);
    }).catch((error) => {
      console.error('Error fetching data:', error);
    });
  }

  const handleClickBtn = async (ehr?: EHRValues) => {
    setCheckOrganization(ehr);
  }

  const handleOnChange = (value: any, event: any) => {
    console.log(event)
    setSelectedOrganization({
      ...selectedOrganization,
      name : event.value,
      address : event.address,
      clientId: event.clientId,
    });
    setIsCheckLoginBtn(true);
  }

  const generateAuthorizationUrl = async (
    selectedOrg: any,
    selectedHospital: DropDownValueModel | null
  ) => {
    if (!selectedHospital || !selectedOrg?.keys) {
      throw new Error('Invalid inputs');
    }
  
    const address = selectedHospital.address;
    const index = address.indexOf('/api');
    const hospitalUrl = address.substring(0, index) + Urls.oauth2_authorize;
    const redirectURI = window.location.href.split('?')[0];
    const json = await decrypt(selectedOrg.keys);
    console.log(json)
    const config = JSON.parse(json);
    localStorage.setItem('config', json);
    const clientId = selectedHospital?.clientId ? selectedHospital?.clientId : config?.clientId;
    const scope = config[Constants.scope].replace(" ", "+");
    window.open(
      `${hospitalUrl}?client_id=${clientId}&response_type=code&redirect_uri=${redirectURI}&scope=${scope}&state=epicauthmobile`,
      '_self'
    );
  };

  const decrypt = async (encryptedText: string): Promise<string> => {
    try {
      const keyBytes = CryptoJS.enc.Utf8.parse(Urls.secretKey.padEnd(32, '\0')); // 32-byte key
      const [ivHex, ...encryptedTextParts] = encryptedText.split(':');
      const iv = CryptoJS.enc.Hex.parse(ivHex);
      const encrypted = CryptoJS.enc.Hex.parse(encryptedTextParts.join(':'));
  
      const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: encrypted,
        iv: iv,
        key: keyBytes,
        algorithm: CryptoJS.algo.AES,
        //mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
  
      const decrypted = CryptoJS.AES.decrypt(cipherParams, keyBytes, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
  
      return decrypted.toString(CryptoJS.enc.Utf8);
  
    } catch (e) {
      throw new Error(`Decryption failed: ${e}`);
    }
  };

  return (
    <>
      <div>
        <h5 className="tw-text-[20px] tw-font-semibold tw-text-[#444444] tw-text-center tw-mt-[20px]">
          Login to EHR with SSO
        </h5>
        <p className="tw-text-[14px] tw-font-medium tw-text-[#888B92] tw-text-center">
          Choose the organization to proceed with EHR SSO login.
        </p>
        {isLoading ? (

          <div className='tw-grid tw-text-center tw-mt-[20px] tw-w-2/5 tw-block tw-m-auto'>
            <Skeleton height={8} mb="xs" />
            <Skeleton height={8} mb="xs" />
            <Skeleton height={8} mb="xs" />
            <Skeleton height={8} mb="xs" />
          </div>
        ): (
          <>
          <div className='tw-flex tw-text-center tw-justify-center tw-gap-5 tw-mt-[20px]'>
            <Button
              type="button"
              className={ehr?.ehr === 'EPIC' ? 'ssoButton' : 'disablebtn'}
              onClick={() => handleClickBtn(ehr)}
            >
              <img src={EpicLogo} alt="Epic Logo" className={ehr?.ehr === 'EPIC' ? 'logoImg' : 'disableImg'} />
            </Button>
            <Button
              type="button"
              className={ehr?.ehr === 'NEXTGEN' ? 'ssoButton' : 'disablebtn'}
            // onClick={() => setLoginType('nextgen')}
            >
              <img
                src={NextGenLogo}
                alt="NextGen Logo"
                className={ehr?.ehr === 'NEXTGEN' ? 'logoImg' : 'disableImg'}
              />
            </Button>
          </div>
          <div className='tw-flex tw-text-center tw-justify-center tw-gap-5 tw-mt-[20px]'>
            <Button
              type="button"
              className={ehr?.ehr === 'CERNER' ? 'ssoButton' : 'disablebtn'}
              //onClick={() => setLoginType('cerner')}
            >
              <img
                src={CernerLogo}
                alt="Cerner Logo"
                className={ehr?.ehr === 'CERNER' ? 'logoImg' : 'disableImg'}
              />
            </Button>
            <Button
              type="button"
              className={ehr?.ehr === 'ATHENA' ? 'ssoButton' : 'disablebtn'}
              //onClick={() => setLoginType('athena')}
            >
              <img
                src={AthenaLogo}
                alt="Athena Logo"
                className={ehr?.ehr === 'ATHENA' ? 'logoImg' : 'disableImg'}
              />
            </Button>
          </div>
          </>
        )}
        {checkOrganization?.ehr === 'EPIC' && (
          <div className='tw-mt-[20px]'>
            <Select
              label="Select Organization"
              placeholder="Select Organization"
              searchable
              data={updatedOrganizationList}
              onChange={(e, event) => handleOnChange(e, event)}
              mb={20}
              style={{ width: '50%', display: 'block', margin: 'auto' }}
            />
          </div>
        )}
        {isCheckLoginBtn && (
          <div className='tw-text-center tw-py-[15px]'>
            <Button type="button" className='tw-w-1/4 sign-btn' onClick={() => generateAuthorizationUrl(ehr, selectedOrganization)}>Proceed To Login</Button>
          </div>
        )}
      </div>
    </>
  )
}

export default LoginWithSSO
