/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Button, Divider, Title } from '@mantine/core';
import { SignInForm } from '@medplum/react';
import { useNavigate } from 'react-router-dom';
import Carousel from '../components/carousel.component';
import { useState } from 'react';

export function SignInPage(): JSX.Element {
  const navigate = useNavigate();
  const [checkLoginType, setCheckLoginType] = useState('');
  const [checkLoginTypeBtn, setCheckLoginTypeBtn] = useState<boolean>(true);
  const slides = [
    { image: './img/banner-01.png', text: '“We’ve been using Untitled to kick start every new project and can’t imagine working without it.”' },
    { image: 'https://wallpapercave.com/wp/wp3386769.jpg', text: '“We’ve been using Untitled to kick start every new project and can’t imagine working without it.”' },
  ];
  
  //
  const handleBtnClick = (type: string) => {
    setCheckLoginType(type);
    setCheckLoginTypeBtn(false);
  }
  return (
    <div className="tw-flex tw-min-h-screen tw-sm:block">
      <div className='tw-flex-initial tw-w-2/4 banner-bg-2 tw-block tw-m-auto'>
        <img src="../img/Pragma_logo_Purple.svg" className='tw-w-[180px] tw-block tw-m-auto' alt='pragmaconnect Logo' />
        {checkLoginTypeBtn && (
          <div className='tw-py-[20px] tw-px-[20px]'>
            <div className='tw-text-center tw-py-[20px]'>
              <Button type="button" className='tw-w-3/5 sign-btn tw-bg-[#9552E8]' onClick={() => navigate('/login')}>Sign in with EHR SSO</Button>
            </div>
            <Divider my="xs" label="OR" labelPosition="center" />
            <div className='tw-text-center tw-mt-[20px]'>
              <Button type="button" className='tw-w-3/5 sign-btn tw-text-center tw-bg-[#9552E8]' onClick={() => handleBtnClick('email')}>Continue with Email</Button>
            </div>
          </div>
        )}
        {checkLoginType === 'email' && (
          <SignInForm
            // Configure according to your settings
            googleClientId=""
            onForgotPassword={() => navigate('/resetpassword')}
            onSuccess={() => navigate('/dashboard')}
          >
            <Title>Login In</Title>
          </SignInForm>
        )}
      </div>
      <div className="tw-w-1/2 banner-bg-1">
          <Carousel slides={slides} />
      </div>
    </div>
  );
}
