/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import LoginWithSSO from './LoginWithSSO';
import Carousel from '../components/carousel.component';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from '../utils/axios';
import { LoginAuthenticationResponse } from '@medplum/core';
import { useMedplum } from '@medplum/react';
import { Loader } from '@mantine/core';

const slides = [
  { image: './img/banner-01.png', text: '“We’ve been using Untitled to kick start every new project and can’t imagine working without it.”' },
  { image: './img/banner-01.png', text: '“We’ve been using Untitled to kick start every new project and can’t imagine working without it.”' },
];

const Login = () => {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const config = JSON.parse(localStorage.getItem('config') || '{}');
  const [startLogin, setStartLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getAccessToken = async () => {
    setIsLoading(true);
    try {
      let formbody: any = {};
      const uri = window.location.href.split('?')[0];
      formbody = {
        code: code,
        redirectUrls: uri,
        tokenEndpoint: config?.apiUrl,
        clientId: config?.clientId,
        grant_type: 'authorization_code',
        client: config?.ehr,
      };
      if (formbody.tokenEndpoint) {
        const res = await axios.post(`/api/sso/get-ehr-token-patient`, formbody);
        localStorage.setItem('ehr_token', JSON.stringify(res));
        localStorage.setItem('patient_id', res.data.patient);
        const { email, password } = res.data;
        //const clientId = config?.clientId;
        medplum.startLogin({ email, password, patient: true }).then(handleAuthResponse).catch(console.error);
      }
    } catch (error) {
      console.error('Error getting access token:', error);
    }
  }

  function handleAuthResponse(response: LoginAuthenticationResponse): void {
    if (response.code) {
      handleCode(response.code);
    }
    if (response.memberships) {
      medplum
        .post('auth/profile', {
          login: response.login,
          profile: response.memberships[0].id,
        })
        .then(handleAuthResponse)
        .catch(console.error);
    }
  }

  function handleCode(code: string): void {
    medplum
      .processCode(code)
      .then((response: any) => {
        console.log('Code processed:', response);
        navigate('/Dashboard');
      })
      .catch(console.error);
  }

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        if (code && !startLogin) {
          if (isMounted) {
            setStartLogin(true);
          }
          await getAccessToken();
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (!startLogin) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [code, state]);

  return (
    <>
      {isLoading && (
        <div className="tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-bg-black tw-opacity-50 tw-z-50">
          <Loader color="blue" className='tw-block tw-m-auto tw-relative tw-top-[50%]'/>
        </div>
      )}
      <div className="tw-flex tw-min-h-screen tw-sm:block">
        <div className="tw-flex-initial tw-w-2/4 banner-bg-2 tw-block tw-m-auto">
          <img
            src="../img/Pragma_logo_Purple.svg"
            className="tw-w-[180px] tw-block tw-m-auto"
            alt="pragmaconnect Logo"
          />
          {!code && (
            <LoginWithSSO />
          )}
        </div>
        <div className="tw-w-1/2 banner-bg-1">
          <Carousel slides={slides} />
        </div>
      </div>
    </>
  );
};

export default Login;
