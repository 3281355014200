/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Checkbox, Loader, Modal, MultiSelect, Radio } from '@mantine/core';
import React from 'react';
import { resources } from '../utils/constant';
import { showNotification } from '@mantine/notifications';
import { useMedplum } from '@medplum/react';
import { exportMedicalRecord } from '../utils/CustomAPI';

interface MedicalRecordProps {
  opened: boolean;
  close: (value: string) => void;
}

const MedicalRecord = (props: MedicalRecordProps) => {
  const medplum = useMedplum();
  const [selectedOption, setSelectedOption] = React.useState('new');
  const [isCheckIncludePHI, setIsCheckIncludePHI] = React.useState(true);
  const [selectedResources, setSelectedResources] = React.useState<string[]>([]);
  const [activeBtn, setActiveBtn] = React.useState('FHIR');
  const [isLoading, setIsLoading] = React.useState('');

  // Download Medical Record
  const downloadMedicalRecord = (exportType: string) => {

    //set Active Tab
    setActiveBtn(exportType);

    //check if selected resources are empty or radio button is not selected
    if (selectedOption === 'new' && selectedResources.length === 0) {
      showNotification({ color: 'red', message: 'Please select at least one resource' });
      return;
    }

    const isGetOldDocFromS3 = selectedOption !== 'new';
    setIsLoading(exportType);
    exportMedicalRecord(medplum, medplum.getProfile()?.id || '', selectedResources?.join(','), isGetOldDocFromS3, exportType, isCheckIncludePHI).then((response) => {
      if (response?.data) {
        setIsLoading('');
        let mimeType;
        let fileExtension;

        switch (exportType) {
          case 'FHIR':
              mimeType = 'application/json';
              fileExtension = 'json';
              break;
          case 'PDF':
              mimeType = 'application/pdf';
              fileExtension = 'pdf';
              break;
          case 'HTML':
              mimeType = 'text/html';
              fileExtension = 'html';
              break;
          default:
              console.error(`Unsupported export type: ${exportType}`);
              return;
        }

       const blob = new Blob([response.data], { type: mimeType });
        const url = window.URL.createObjectURL(blob);
        if (exportType === 'HTML') {
          window.open(url, '_blank');
          // Create a link to download the file
          const a = document.createElement('a');
          a.href = url;
          a.download = `${medplum.getProfile()?.name?.[0].text}_MedicalRecord.${fileExtension}`;
          a.click();
        } else {
          const a = document.createElement('a');
          a.href = url;
          a.download = `${medplum.getProfile()?.name?.[0].text}_MedicalRecord.${fileExtension}`;
          a.click();
          window.URL.revokeObjectURL(url);
        }

        showNotification({ color: 'green', message: 'Medical record exported successfully' });
        props.close('');
      }
    }).catch(() => {
      showNotification({ color: 'red', message: 'Failed to download Medical Record Summary' });
    });

  };

  return (
    <>
      <Modal.Root opened={props.opened} onClose={() => props.close('')} size="lg" className="appointment">
        <Modal.Overlay />
        <form>
          <Modal.Content>
            <Modal.Header style={{ borderBottom: '1px solid #EEF1F6'}}>
              <Modal.Title>
                <div>
                  <h5 className='tw-font-semibold tw-text-[18px] tw-mb-2'>Medical Record Summary</h5>
                  <p className='tw-text-[#475467] tw-text-[13px] tw-font-normal'>Generate a new medical record summary, or instantly download an existing one.</p>
                </div>
              </Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>
            <Modal.Body>
              <div className='tw-pt-7 tw-px-4'>
                <Radio.Group
                  mb={30}
                  value={selectedOption}
                  onChange={setSelectedOption}
                >
                    <Radio iconColor="white" color="#9552E8" value="new" label="Generate New" mb={15} />
                    <Radio iconColor="white" color="#9552E8" value="existing" label="Get Summary from 8/23/2024, 3:18:41 PM" />
                </Radio.Group>
                {selectedOption === 'new' && (
                  <>
                    <MultiSelect
                        label="Select Resources"
                        placeholder="Select resources"
                        data={resources}
                        mb={20}
                        onChange={(value) => setSelectedResources(value)}
                    />
                    <Checkbox
                        label="Include PHI Data"
                        checked
                        onChange={(event) => setIsCheckIncludePHI(event.currentTarget.checked)}
                        styles={{ label: { fontSize: '14px', fontWeight: 500 } }}
                    />
                  </>
                )}
              </div>
            </Modal.Body>
            <hr className='tw-m-0 tw-border-t tw-border-[#EEF1F6]' />
            <div>
              <div className='tw-flex tw-justify-end tw-py-3 tw-px-4'>
                <button 
                  type='button' 
                  className='tw-text-[#475467] tw-font-semibold tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-text-center tw-inline-flex tw-items-center tw-me-2 tw-mb-2 tw-border tw-border-[#D6BBFB]' 
                  onClick={() => downloadMedicalRecord('FHIR')}
                  style={{ backgroundColor: activeBtn === 'FHIR' ? '#9552E8' : 'transparent', color: activeBtn === 'FHIR' ? 'white' : '#475467' }}
                >
                  FHIR {isLoading === 'FHIR' && <Loader color="white" size="xs" ml={10}/>}
                </button>
                <button 
                  type='button' 
                  className='tw-bg-[#9552E8] tw-text-white tw-font-semibold tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-text-center tw-inline-flex tw-items-center tw-me-2 tw-mb-2 tw-border tw-border-[#D6BBFB]'
                  onClick={() => downloadMedicalRecord('PDF')}
                  style={{ backgroundColor: activeBtn === 'PDF' ? '#9552E8' : 'transparent', color: activeBtn === 'PDF' ? 'white' : '#475467' }}
                >
                  PDF {isLoading === 'PDF' && <Loader color="white" size="xs" ml={10}/>}
                </button>
                <button 
                  type='button' 
                  className='tw-bg-[#9552E8] tw-text-white tw-font-semibold tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-text-center tw-inline-flex tw-items-center tw-me-2 tw-mb-2 tw-border tw-border-[#D6BBFB]'
                  onClick={() => downloadMedicalRecord('HTML')}
                  style={{ backgroundColor: activeBtn === 'HTML' ? '#9552E8' : 'transparent', color: activeBtn === 'HTML' ? 'white' : '#475467' }}
                >
                  HTML {isLoading === 'HTML' && <Loader color="white" size="xs" ml={10}/>}
                </button>
              </div>
            </div>
          </Modal.Content>
        </form>
      </Modal.Root>
    </>
  );
};

export default MedicalRecord;
