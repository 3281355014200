/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { AreaChart, Area, Tooltip, ResponsiveContainer } from 'recharts';
// import { IconArrowUp } from '@tabler/icons-react';
import { DataPoint } from '../../interfaces/DataPoint';
import { Skeleton } from '@mantine/core';

interface BloodPressureProps {
  bloodPressureData: DataPoint[];
  isLoading?: boolean;
}

const BloodPressure = (props: BloodPressureProps) => {
  return (
    <>
      <div className="tw-mt-6 xs:tw-mt-2 tw-px-5 tw-py-4 tw-block tw-w-[50%] tw-h-fit tw-bg-white tw-border border-[#F2F2F2] tw-rounded-[12px] featured-card">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-1">
          <h5 className="tw-text-[16px] tw-font-semibold tw-tracking-tight tw-text-[#101828] tw-pb-0">
            Blood Pressure
          </h5>
        </div>
        {!props.isLoading ? (
          <div className="tw-flex tw-justify-between tw-mt-5">
            <div className='tw-grid tw-w-[50%]'>
              <span className="tw-font-semibold tw-text-[#101828] tw-text-[25px] tw-mb-3">{props?.bloodPressureData?.[0]?.value} mmHg</span>
              {/* <span className='tw-text-[#067647] tw-flex tw-items-center tw-font-medium'>
                <IconArrowUp size={15} color='#17B26A'/>90%
                <span className='tw-ml-1 tw-text-[#475467] tw-font-medium'>vs last month</span>
              </span> */}
            </div>
            <div className='tw-w-[50%]'>
              <ResponsiveContainer>
                <AreaChart data={props?.bloodPressureData} barSize={5}>
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#17B26A" stopOpacity={0.8}/>
                      <stop offset="95%" stopColor="#17B26A" stopOpacity={0}/>
                    </linearGradient>
                  </defs>
                  <Tooltip cursor={false} />
                  <Area
                    dataKey="value"
                    fill="url(#colorUv)"
                    stroke='#17B26A'
                    strokeWidth={2}
                    name='Systolic Blood Pressure'
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : (
          <div className='tw-grid tw-text-center tw-mt-[20px] tw-block tw-m-auto'>
            <Skeleton height={8} mb="xs" />
            <Skeleton height={8} mb="xs" />
            <Skeleton height={8} mb="xs" />
          </div>
        )}
      </div>
    </>
  );
};

export default BloodPressure;
