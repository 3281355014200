/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { IconBell, IconChevronRight } from '@tabler/icons-react';
import React, { useEffect } from 'react';
import { useMedplum } from '@medplum/react';
import { Skeleton } from '@mantine/core';

interface NotificationCardData {
  id: string;
  note: string;
  title: string;
}

const NotificationCard = () => {
  const medplum = useMedplum();
  const [notificationData, setNotificationData] = React.useState<NotificationCardData[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true);
    medplum
      .search('CommunicationRequest' as 'CommunicationRequest', {
        _count: 10,
        _offset: 0, // Add your offset value here
        _sort: '-_lastUpdated',
        _total: 'accurate',
        patient: `Patient/${medplum.getProfile()?.id}`,
      })
      .then((res: any) => {
        setIsLoading(false);
        setNotificationData(
          res.entry.map((data: any) => ({
            id: data.resource.id,
            note: data.resource?.note?.[0]?.text,
            title: data.resource?.about?.[0]?.display,
          }))
        );
      })
      .catch((err: any) => {
        console.error(err);
      });
  }, []);

  return (
    <>
      <div className="upcomming-card tw-mt-6 tw-block tw-w-[50%] tw-bg-white tw-border border-[#EAECF0] tw-rounded-lg tw-h-[400px] scrollable_div tw-p-5">
        <div className="tw-flex tw-items-center tw-justify-between">
          <h5 className="tw-text-[16px] tw-font-semibold tw-text-[#101828] tw-mt-[8px]">
            Notifications ({notificationData.length})
          </h5>
          <span className="tw-text-[#344054] tw-font-medium tw-mt-[8px] tw-gap-2 tw-flex tw-items-center">
            View all <IconChevronRight size={15} />
          </span>
        </div>
        <hr className="h-px tw-my-4 bg-gray-200 border-0 dark:bg-gray-700"></hr>
        {!isLoading ? (notificationData?.length > 0 ? (notificationData?.map((notifications, index) => (
          <div className="tw-bg-[#F9FAFB] tw-py-3 tw-mb-3 tw-px-1 tw-border tw-border-[#F5F5F5]" key={index}>
            <div className="tw-flex tw-gap-5">
              <div className="tw-bg-[#F9F5FF] tw-p-2 tw-h-[55px] tw-w-[55px] tw-rounded-full">
                <div className="tw-bg-[#F4EBFF] tw-p-1.5 tw-h-[35px] tw-w-[35px] tw-rounded-full">
                  <IconBell color="#9552E8" />
                </div>
              </div>
              <div>
                <h5 className="tw-text-[14px] tw-font-semibold tw-tracking-tight tw-text-[#101828] tw-pb-1">
                  {notifications?.title}
                </h5>
                <span className="tw-text-[14px] tw-text-[#475467] ">{notifications?.note}</span>
              </div>
            </div>
          </div>
        ))) : (
          <div className="tw-text-center tw-mt-5 tw-text-[14px] tw-text-[#475467]">
            No notifications found
          </div>
        )) : (
          <div className='tw-grid tw-text-center tw-mt-[20px] tw-block tw-m-auto'>
            <Skeleton height={8} mb="xs" />
            <Skeleton height={8} mb="xs" />
            <Skeleton height={8} mb="xs" />
            <Skeleton height={8} mb="xs" />
          </div>
        )}
      </div>
    </> 
  );
};

export default NotificationCard;
