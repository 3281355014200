import {
  AppShell,
  Loading,
  Logo,
  useMedplum,
  useMedplumProfile,
} from '@medplum/react';
import {
  IconCalendarTime,
  IconHome,
  IconInvoice,
  IconMail,
  IconMicroscope,
  IconNotes,
  IconVideo,
} from '@tabler/icons-react';
import { Suspense } from 'react';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { ResourceCreatePage } from './pages/resource/ResourceCreatePage';
import { HomePage } from './pages/HomePage';
import { OnboardingPage } from './pages/OnboardingPage';
import { SearchPage } from './pages/SearchPage';
import { SignInPage } from './pages/SignInPage';
import { EditTab } from './pages/patient/EditTab';
import { EncounterTab } from './pages/patient/EncounterTab';
import { PatientPage } from './pages/patient/PatientPage';
import { PatientSearchPage } from './pages/patient/PatientSearchPage';
import { TimelineTab } from './pages/patient/TimelineTab';
import { ResourceDetailPage } from './pages/resource/ResourceDetailPage';
import { ResourceEditPage } from './pages/resource/ResourceEditPage';
import { ResourceHistoryPage } from './pages/resource/ResourceHistoryPage';
import { ResourcePage } from './pages/resource/ResourcePage';
import { CommunicationTab } from './pages/patient/CommunicationTab';
import { TaskTab } from './pages/patient/TaskTab';
import Index from './dashboard';
import './index.css';
import './App.css';
import '@mantine/dates/styles.css';
import { ListingPage } from './dashboard/HomePage';
import Patient from './dashboard/PatientResourcePage';
import Login from './pages/Login';

export function App(): JSX.Element | null {
  const medplum = useMedplum();
  const profile = useMedplumProfile();
  const [searchParams] = useSearchParams();

  if (medplum.isLoading()) {
    return null;
  }

  return (
    <AppShell
      logo={<Logo size={150} />}
      pathname={location.pathname}
      searchParams={searchParams}
      menus={[
        {
          title: '',
          links: [
            { icon: <IconHome />, label: 'Dashboard', href: '/dashboard' },
            { icon: <IconCalendarTime />, label: 'Appointments', href: '/Appointment' },
            // { icon: <IconBrandDatabricks />, label: 'Waiting List', href: '/WaitingList' },
            // { icon: <IconUsers />, label: 'Patients', href: '/Patient' },
            // { icon: <IconClockHour2 />, label: 'Schedule Timing', href: '#' },
            { icon: <IconMail />, label: 'Messages', href: '#' },
            { icon: <IconVideo />, label: 'Telehealth', href: '#' },
            { icon: <IconMicroscope />, label: 'Lab Tests', href: '#' },
            { icon: <IconInvoice />, label: 'Invoices', href: '#' },
            { icon: <IconNotes />, label: 'Notes', href: '#' },
            // { icon: <IconSettings2 />, label: 'Settings', href: '#' },
            // { icon: <IconLogout />, label: 'Logout', href: '#' },
          ],
        }
      ]}
      resourceTypeSearchDisabled={true}
    >
      <Suspense fallback={<Loading />}>
        <Routes>
          {profile ? (
            <>
              <Route path="/" element={<HomePage />} />
              <Route path="/Patient/:patientId" element={<PatientPage />}>
                <Route path="edit" element={<EditTab />} />
                <Route path="encounter" element={<EncounterTab />} />
                <Route path="communication" element={<CommunicationTab />} />
                <Route path="communication/:id" element={<CommunicationTab />} />
                <Route path="task/:id/*" element={<TaskTab />} />
                <Route path="timeline" element={<TimelineTab />} />
                <Route path=":resourceType" element={<PatientSearchPage />} />
                <Route path=":resourceType/new" element={<ResourceCreatePage />} />
                <Route path=":resourceType/:id" element={<ResourcePage />}>
                  <Route path="" element={<ResourceDetailPage />} />
                  <Route path="edit" element={<ResourceEditPage />} />
                  <Route path="history" element={<ResourceHistoryPage />} />
                </Route>
                <Route path="" element={<TimelineTab />} />
              </Route>
              <Route path="/onboarding" element={<OnboardingPage />} />
              {/* <Route path="/signin" element={<SignInPage />} /> */}
              <Route path="/:resourceType" element={<SearchPage />} />
              <Route path="/:resourceType/new" element={<ResourceCreatePage />} />
              <Route path="/:resourceType/:id" element={<ResourcePage />}>
                <Route path="" element={<ResourceDetailPage />} />
                <Route path="edit" element={<ResourceEditPage />} />
                <Route path="history" element={<ResourceHistoryPage />} />
              </Route>
              <Route path="/dashboard" element={<Index />} />
              <Route path="/Appointment" element={<ListingPage />} />
              <Route path="/WaitingList" element={<ListingPage />} />
              <Route path="/Patient" element={<ListingPage />} />
              <Route path="/Patient/:id/details" element={<Patient />} />
            </>
          ) : (
            <>
              <Route path="/signin" element={<SignInPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/signin" replace />} />
            </>
          )}
        </Routes>
      </Suspense>
    </AppShell>
  );
}
