/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';

interface GroupButtonsProps {
  value: [{id: number, name: string}];
  buttonHandleClick: (data: {id: number, name: string}) => void;
}

const GroupButtons = (props: GroupButtonsProps) => {
  const [btnActive, setBtnActive] = React.useState<number>(props?.value?.[0].id);
  const handleClick = (data: {id: number, name: string}) => {
    setBtnActive(data.id);
    props.buttonHandleClick(data);
  }
  return (
    <>
      <div className="tw-inline-flex tw-rounded-md time-filter" role="group">
        {props?.value.map((data) => (
          <button key={data.id} type="button" className="tw-px-4 tw-py-2.5 tw-text-sm tw-font-semibold tw-text-[#344054] tw-text-[14px] tw-bg-white tw-border tw-border-[#D0D5DD]"
          onClick={() => handleClick(data)} style={{ backgroundColor: `${btnActive === data.id ? '#F9FAFB' : 'white'}`}}>
            {data.name}
          </button>
        ))}
      </div>
    </>
  )
}

export default GroupButtons;
