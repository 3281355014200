/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { showNotification } from '@mantine/notifications';
import axios from './axios';
import { MedplumClient } from '@medplum/core';

export const listingPage = async (medplum: MedplumClient, apiUrl: string, count?: any, offset?: any, search?: any) => {
    const token = await medplum.getAccessToken();
    let queryParameters = '';
    let sortingParameters = '';
    let status = '';
    let statusParam = '';
    // Loop through the filters array to build the query string
    if (search?.filters) {
      search.filters.forEach((filter: { code: any; value: any }) => {
        queryParameters += `&${filter.code}=${filter.value}`;
      });
    }
    if (search?.sortRules) {
      search.sortRules.forEach((filter: { code: string; descending: boolean }) => {
        if (filter.descending) {
          sortingParameters += `&_sort=-${filter.code}`;
        } else {
          sortingParameters += `&_sort=${filter.code}`;
        }
      });
    }
    
    if(location.pathname === '/WaitingList') {
      status = 'waitlist';
    } else {
      status = 'any';
    }

    if(location.pathname === '/WaitingList') {
      statusParam = `&status=${status}`;
    }
    const response = await axios.get(`/healthside/${apiUrl}?_count=${count}&_offset=${offset}${sortingParameters}&_sort=-_lastUpdated&_total=accurate${queryParameters}${statusParam}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
};

export const getSlots = async (medplum: MedplumClient, serviceId: string, startDate: string) => {
  const token = await medplum.getAccessToken();
  const response = await axios.get(`/fhir/R4/Slot?_count=20&_offset=0&_sort=start&_total=accurate&schedule.actor=HealthcareService/${serviceId}&status=free&&start=ge${startDate}T00:00:00Z&start=lt${startDate}T23:59:00Z`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response;
};

export const bookAppointment = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();
  const response = await axios.post(`/healthside/book-appointment`, payload,  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response;
};

export const updateAppointment = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();
  const response = await axios.post(`/healthside/update-appointment`, payload,  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response;
};

export const cancelAppointment = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();
  const response = await axios.post(`/healthside/cancel-appointment`, payload,  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response;
};

export const createUpdatePatient = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/create-update-patient`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    }
  });
  return response.data;
}

export const createUpdatePatientResource = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/create-update-patientdata-resource`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    }
  });
  return response.data;
}

export const createUpdatePatientAccount = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/healthside/create-update-patient-account`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    }
  });
  return response.data;
}

export const getAppointment = async (medplum: MedplumClient, patientId: string,) => {
  const token = await medplum.getAccessToken();
  const response = await axios.get(`fhir/R4/Appointment?_count=20&_offset=0&_sort=-_lastUpdated&_total=accurate&patient=${patientId}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response;
};

export const assessmentInsightsSummary = async (medplum: MedplumClient, patientId: string) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(`api/pragmaconnect/get-patient-assessment-insights?patient_id=${patientId}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error: any) {
    console.error(error);
    showNotification({ color: 'red', message: error.message });
    return null;
  }
};

export const progressReportSummary = async (medplum: MedplumClient, patientId: string) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(`api/pragmaconnect/get-patient-clinical-notes-insights?patient_id=${patientId}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error: any) {
    console.error(error);
    showNotification({ color: 'red', message: error.message });
    return null;
  }
};

export const fetchPatientDetails = async (medplum: any, query: string) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.post(
      `/fhir/R4/$graphql`,
      {
        query: query,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getOrganization = async (medplum: MedplumClient, query: string) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(`api/organization/get-organization-by-name?name=${query}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response?.data;
  } catch (error) {
    console.error(error);
  }
};

export const exportClinicalNote = async (medplum: MedplumClient, patientId: string) => {
  try {
    const token = await medplum.getAccessToken();

    const response = await axios.get(`/api/pragmaconnect/download-clinical-note?id=${patientId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    });
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
};

export const getClinicalNotes = async (medplum: MedplumClient, patientId: string) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(`/api/analytics/get-clinical-note-by-patientId?patientId=${patientId}`, {
      headers: {
        Accept: 'application/fhir+json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const exportMedicalRecord = async (medplum: MedplumClient, patientId: string, resources: string, isGetOldDocFromS3: boolean, exportType: string, isCheckIncludePHI: boolean) => {
  try {
    const token = await medplum.getAccessToken();

    const url = `/api/pragmaconnect/export-patient-data?patientId=${patientId}&resources=${resources}&isGetOldDocFromS3=${isGetOldDocFromS3}&exportType=${exportType}&isPII=${isCheckIncludePHI}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    });
    return response;
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
};